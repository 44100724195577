import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { REJECT_PRE_OFFER_EVALUATION_REQUESTS } from '@fingo/lib/graphql/evaluation_requests/mutations';
import { GET_PREOFFERS_REQUESTS, GET_COMPANY_EVALUATIONS } from '@fingo/lib/graphql';
import riskEvaluationAcceptRejectColumns from '../../../constants/risk-evaluations-accept-reject-columns';
import RejectEvaluationRequestsForm from '../forms/RejectEvaluationRequestsForm';

const FACTORING_COLUMNS = [
  'folio',
  'amountWithIva',
  'receiver_sortable',
  'dateIssued',
  'rejectionReason',
  'offerDateToPay',
  'receiverBlacklist',
];

const RejectPreOfferEvaluationRequestsDialog = ({ documentsToEvaluate, onClose }) => {
  const [selectedPreOfferEvaluation, setSelectedPreOfferEvaluation] = useState('');
  const [openOtherRejectionReason, setOpenOtherRejectionReason] = useState(false);
  const [informClients, setInformClients] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [analystDescription, setAnalystDescription] = useState('');
  const [preOfferEvaluationRequests, setPreOfferEvaluationRequests] = useState(
    documentsToEvaluate.map((document) => ({
      ...document,
      rejectionReason: '',
      otherRejectionReason: '',
    })),
  );
  const [rejectPreOfferEvaluationRequests, { loading, error }] = useMutation(
    REJECT_PRE_OFFER_EVALUATION_REQUESTS,
    {
      variables: {
        resolution: {
          requests: preOfferEvaluationRequests.map(
            ({ preoffer, rejectionReason, otherRejectionReason }) => ({
              id: preoffer.preofferevaluationrequest.id,
              rejectionReason,
              otherRejectionReason,
            }),
          ),
          analystDescription,
          informClients,
          sendEmail,
        },
      },
      refetchQueries: [GET_PREOFFERS_REQUESTS, GET_COMPANY_EVALUATIONS],
      onCompleted: () => {
        onClose();
      },
    },
  );

  const updatePreOfferEvaluationRequests = (id, event) => {
    const { name, value } = event.target;
    const newData = [...preOfferEvaluationRequests];
    newData.find((row) => row.id === id)[name] = value;
    setPreOfferEvaluationRequests(newData);
  };

  const columns = useInvoicePreColumns(
    riskEvaluationAcceptRejectColumns(
      updatePreOfferEvaluationRequests,
      setOpenOtherRejectionReason,
      setSelectedPreOfferEvaluation,
    ),
  ).filter((column) => FACTORING_COLUMNS.includes(column.field));

  const disabled = preOfferEvaluationRequests.some((document) => document.rejectionReason === '');
  return (
    <RejectEvaluationRequestsForm
      rejectEvaluationRequests={rejectPreOfferEvaluationRequests}
      analystDescription={analystDescription}
      setAnalystDescription={setAnalystDescription}
      columns={columns}
      disabled={disabled}
      informClients={informClients}
      setInformClients={setInformClients}
      sendEmail={sendEmail}
      setSendEmail={setSendEmail}
      loading={loading}
      error={error}
      rows={preOfferEvaluationRequests}
      openOtherRejectionReason={openOtherRejectionReason}
      setOpenOtherRejectionReason={setOpenOtherRejectionReason}
      updatePreOfferEvaluationRequests={updatePreOfferEvaluationRequests}
      selectedPreOfferEvaluation={selectedPreOfferEvaluation}
    />
  );
};

RejectPreOfferEvaluationRequestsDialog.propTypes = {
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RejectPreOfferEvaluationRequestsDialog;
