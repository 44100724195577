/* eslint-disable react/prop-types */
import { FullHeightBox } from '@fingo/lib/components/boxes';
import { useBooleanState } from '@fingo/lib/hooks';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CancelRounded from '@mui/icons-material/CancelRounded';
import HelpRounded from '@mui/icons-material/HelpRounded';
import WarningRounded from '@mui/icons-material/WarningRounded';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import React, { useCallback, useMemo, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InvoiceType } from '@fingo/lib/propTypes';
import RiskPredictionResults from './RiskPredictionResults';
import RatificationResultVariableContribution from './RatificationResultVariableContribution';
import CreditLineEvaluation from './CreditLineEvaluation';
import TreeRiskResults from './TreeRiskResults';
import RasRiskResults from './RasRiskResults';

const PADDING_LEFT_BUTTON = 4;

const CollapsibleListItemButton = ({
  title,
  children,
  componentValue,
  setComponentIndex,
  componentIndex,
}) => {
  const show = useMemo(() => {
    if (children === true) {
      return <CheckCircleIcon color="success" />;
    }
    if (children === false) {
      return <CancelRounded color="error" />;
    }
    if (children === 'warning') {
      return <WarningRounded color="warning" />;
    }
    if (children === undefined) {
      return <HelpRounded color="warning" />;
    }
    return children;
  }, [children]);
  const setter = useCallback(() => setComponentIndex(componentValue), [componentValue]);
  return (
    <ListItemButton
      sx={{
        pl: PADDING_LEFT_BUTTON,
        bgcolor: componentIndex === componentValue ? 'white' : 'inherit',
      }}
      onClick={setter}
    >
      <ListItemText primary={title} />
      <ListItemIcon>
        {show}
      </ListItemIcon>
    </ListItemButton>
  );
};

const AutomaticEvaluationContent = ({ invoice }) => {
  const [modelOpen, toggleModelOpen] = useBooleanState(true);
  const [alertsOpen, toggleAlertsOpen] = useBooleanState(true);
  const [componentIndex, setComponentIndex] = useState(0);
  const { riskEvaluationModel, amountWithIva, preoffer:
    { preofferevaluationrequest: { riskautomaticevaluationlogSet } } } = invoice;
  const riskautomaticevaluationlog = riskautomaticevaluationlogSet[0];
  const component = useMemo(() => {
    if (componentIndex === 0) {
      return <RiskPredictionResults riskEvaluationId={riskEvaluationModel?.id} />;
    }
    if (componentIndex === 1) {
      return (
        <RatificationResultVariableContribution riskEvaluationId={riskEvaluationModel?.id} />
      );
    }
    if (componentIndex === 2) {
      return (
        <CreditLineEvaluation
          masterEntityId={invoice.company.id}
          masterEntityName={invoice.company.name}
          invoiceAmount={amountWithIva}
        />
      );
    }
    if (componentIndex === 3) {
      return (
        <CreditLineEvaluation
          masterEntityId={invoice.receiver.id}
          masterEntityName={invoice.receiver.name}
          invoiceAmount={amountWithIva}
        />
      );
    }
    if (componentIndex === 4) {
      return (
        <TreeRiskResults
          documentId={invoice.id}
          documentType="invoice"
          riskEvaluation={riskEvaluationModel}
        />
      );
    }
    if (componentIndex === 5) {
      return (
        <RasRiskResults
          documentId={invoice.id}
          documentType="invoice"
          riskEvaluation={riskEvaluationModel}
        />
      );
    }
    return null;
  }, [invoice, componentIndex]);
  const RiskChildren = useMemo(() => {
    const results = riskEvaluationModel?.riskPredictionResults ?? [];
    if (!results.length) return 'warning';
    for (let i = 0; i < results.length; i += 1) {
      if (results[i].isRejected) return false;
      if (results[i].isAccepted) return true;
    }
    return undefined;
  }, [riskEvaluationModel]);
  const RatificationChildren = useMemo(() => {
    const WithOutRatification = [
      'WHITHOUT_RATIFICATION_CLIENT',
      'WHITHOUT_RATIFICATION_DEBTOR',
      'WITHOUT_RESTRICTION_CLIENT',
      'WITHOUT_RESTRICTION_DEBTOR',
    ];
    const WithRatification = ['WITH_RATIFICATION'];
    const results = riskEvaluationModel?.ratificationResults ?? [];
    if (!results.length) return 'warning';
    for (let i = 0; i < results.length; i += 1) {
      if (WithOutRatification.includes(results[i].ratificationResult)) return true;
      if (WithRatification.includes(results[i].ratificationResult)) return false;
    }
    return undefined;
  }, [riskEvaluationModel]);
  return (
    <FullHeightBox display="flex">
      <Drawer
        variant="permanent"
        open
        sx={(fingoTheme) => ({
          '& .MuiDrawer-paper': {
            position: 'inherit',
            bgcolor: 'background.light',
            border: 0,
          },
          [fingoTheme.breakpoints.down('md')]: {
            display: 'none',
            width: 0,
          },
        })}
        anchor="left"
      >
        <List
          sx={{ width: '100%', maxWidth: 360 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton onClick={toggleModelOpen}>
            <ListItemText primary="Modelos predictivos" />
            {modelOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          </ListItemButton>
          <Collapse in={modelOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {[
                {
                  key: 'risk',
                  title: 'Modelo de riesgo',
                  children: RiskChildren,
                },
                {
                  key: 'ratification',
                  title: 'Modelo de ratificación',
                  children: RatificationChildren,
                },
                {
                  key: 'clientLine',
                  title: 'Línea por cliente',
                  children: (
                    riskautomaticevaluationlog?.clientLineResult
                  ),
                },
                {
                  key: 'debtorLine',
                  title: 'Línea por deudor',
                  children: (
                    riskautomaticevaluationlog?.debtorLineResult
                  ),
                },
              ].map((value, index) => (
                <CollapsibleListItemButton
                  {...value}
                  componentValue={index}
                  setComponentIndex={setComponentIndex}
                  componentIndex={componentIndex}
                />
              ))}
            </List>
          </Collapse>
          <ListItemButton onClick={toggleAlertsOpen}>
            <ListItemText primary="Alertas" />
            {alertsOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />}
          </ListItemButton>
          <Collapse in={alertsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {[
                {
                  key: 'tree',
                  title: 'Arbol',
                  children: riskEvaluationModel.treeClientResult
                  && riskEvaluationModel.treeDebtorResult
                  && riskEvaluationModel.treeClientDebtorResult,
                },
                {
                  key: 'ras',
                  title: 'RAS',
                  children: (riskEvaluationModel.rasClientResult * 0.4
                    + riskEvaluationModel.rasDebtorResult * 0.4
                    + riskEvaluationModel.rasClientDebtorResult * 0.2).toFixed(2),
                },
              ].map((value, index) => (
                <CollapsibleListItemButton
                  {...value}
                  componentValue={index + 4}
                  setComponentIndex={setComponentIndex}
                  componentIndex={componentIndex}
                />
              ))}
            </List>
          </Collapse>
        </List>
      </Drawer>
      <FullHeightBox flex={1} p={4} bgcolor="white">
        {component}
      </FullHeightBox>
    </FullHeightBox>
  );
};

AutomaticEvaluationContent.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default AutomaticEvaluationContent;
