import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useOrderingLucilaPreColumns from '@fingo/lib/constants/ordering-lucila-pre-columns';
import { APPROVE_ORDERING_SIMULATION, GET_PREOFFERS_REQUESTS_ORDERING, GET_COMPANY_ORDERING_EVALUATIONS } from '@fingo/lib/graphql';
import riskEvaluationAcceptRejectColumns from '../../../constants/risk-evaluations-accept-reject-columns';
import { ORDERING_COLUMNS } from '../../../constants/risk-evaluations';
import { documentToAcceptOrderingSimulationEvaluationRequestResolution } from '../../../helpers/risk-evaluations';
import AcceptEvaluationRequestsForm from '../forms/AcceptEvaluationRequestsForm';

const AcceptOrderingSimulationEvaluationRequestsDialog = ({ onClose, documentsToEvaluate }) => {
  const [analystDescription, setAnalystDescription] = useState('');
  const [informClients, setInformClients] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [orderingSimulationEvaluationRequests, setOrderingSimulationEvaluationRequests] = useState(
    documentsToEvaluate.map((document) => ({
      ...document,
      fundingDays: 60,
      monthlyRate: document.orderingsimulation.factoringMonthlyRate,
      defaultRate: document.orderingsimulation.factoringDefaultRate,
      retentionRate: document.orderingsimulation.factoringRetentionRate,
      orderingMonthlyRate: document.orderingsimulation.orderingMonthlyRate,
      orderingDefaultRate: document.orderingsimulation.orderingDefaultRate,
      orderingRetentionRate: document.orderingsimulation.orderingRetentionRate,
    })),
  );

  const [approveOrderingSimulation, { loading, error }] = useMutation(APPROVE_ORDERING_SIMULATION, {
    variables: {
      requestResolutions: orderingSimulationEvaluationRequests.map(
        (document) => documentToAcceptOrderingSimulationEvaluationRequestResolution(document),
      ),
      analystDescription,
      informClients,
      sendEmail,
    },
    refetchQueries: [GET_PREOFFERS_REQUESTS_ORDERING, GET_COMPANY_ORDERING_EVALUATIONS],
    onCompleted: () => {
      onClose();
    },
  });

  const updatePreoffer = (id, event, type) => {
    const { name, value, checked } = event.target;
    const newData = [...orderingSimulationEvaluationRequests];
    newData.find((row) => row.id === id)[name] = { value, checked }[type];
    setOrderingSimulationEvaluationRequests(newData);
  };

  const columns = useOrderingLucilaPreColumns(
    riskEvaluationAcceptRejectColumns(updatePreoffer),
  ).filter((column) => ORDERING_COLUMNS.includes(column.field));
  const companyRestrictions = (
    documentsToEvaluate[0]?.company?.companyevaluationrestrictionsSet?.filter(
      ({ status }) => status === 'CREATED',
    ) ?? []);

  return (
    <AcceptEvaluationRequestsForm
      company={documentsToEvaluate[0]?.company}
      companyRestrictions={companyRestrictions}
      acceptEvaluationRequests={approveOrderingSimulation}
      analystDescription={analystDescription}
      setAnalystDescription={setAnalystDescription}
      columns={columns}
      disabled={false}
      informClients={informClients}
      setInformClients={setInformClients}
      sendEmail={sendEmail}
      setSendEmail={setSendEmail}
      loading={loading}
      error={error}
      rows={orderingSimulationEvaluationRequests}
    />
  );
};

AcceptOrderingSimulationEvaluationRequestsDialog.propTypes = {
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        companyevaluationrestrictionsSet: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            restriction: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            resolutionExplanation: PropTypes.string.isRequired,
          }),
        ),
      }),
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AcceptOrderingSimulationEvaluationRequestsDialog;
