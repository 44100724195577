import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useInvoicePreColumns } from '@fingo/lib/constants';
import { ACCEPT_PRE_OFFER_EVALUATION_REQUESTS } from '@fingo/lib/graphql/evaluation_requests/mutations';
import { GET_PREOFFERS_REQUESTS, GET_COMPANY_EVALUATIONS } from '@fingo/lib/graphql';
import riskEvaluationAcceptRejectColumns from '../../../constants/risk-evaluations-accept-reject-columns';
import {
  WHITHOUT_RATIFICATION_STATUS,
} from '../../../constants/risk-evaluations';
import { documentToAcceptPreOfferEvaluationRequestResolution } from '../../../helpers/risk-evaluations';
import AcceptEvaluationRequestsForm from '../forms/AcceptEvaluationRequestsForm';
import { companyRiskRestrictionsStatus } from '../../../../treasury/helpers/treasury';

const AcceptPreOfferEvaluationRequestsDialog = (
  {
    documentsToEvaluate,
    onClose,
    factoringColumns,
    defaultRatification,
    defaultFundingDays,
  },
) => {
  const [informClients, setInformClients] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [analystDescription, setAnalystDescription] = useState('');

  const [preOfferEvaluationRequests, setPreOfferEvaluationRequests] = useState(
    documentsToEvaluate.map((document) => ({
      ...document,
      monthlyRate: document.preoffer.monthlyRate,
      defaultRate: document.preoffer.defaultRate,
      retentionRate: document.preoffer.retentionRate,
      ratification: defaultRatification,
      reviewOc: false,
      reviewHes: false,
      reviewPortalScreenshot: false,
      hasReceptionAcknowledgment: false,
      fundingDays: defaultFundingDays,
    })),
  );
  const [acceptPreOfferEvaluationRequests, { loading, error }] = useMutation(
    ACCEPT_PRE_OFFER_EVALUATION_REQUESTS,
    {
      variables: {
        resolution: {
          requests: preOfferEvaluationRequests.map(
            (document) => documentToAcceptPreOfferEvaluationRequestResolution(document),
          ),
          analystDescription,
          informClients,
          sendEmail,
        },
      },
      refetchQueries: [GET_PREOFFERS_REQUESTS, GET_COMPANY_EVALUATIONS],
      onCompleted: () => {
        onClose();
      },
    },
  );

  const updatePreoffer = (id, event, type) => {
    const { name, value, checked } = event.target;
    const newData = [...preOfferEvaluationRequests];
    newData.find((row) => row.id === id)[name] = { value, checked }[type];
    setPreOfferEvaluationRequests(newData);
  };

  const columns = useInvoicePreColumns(riskEvaluationAcceptRejectColumns(updatePreoffer)).filter(
    (column) => factoringColumns.includes(column.field),
  );

  const disabled = preOfferEvaluationRequests.some((preOffer) => {
    if (preOffer.ratification === '') return true;
    if (
      WHITHOUT_RATIFICATION_STATUS.includes(preOffer.ratification)
      && !preOffer.reviewOc
      && !preOffer.reviewHes
      && !preOffer.reviewPortalScreenshot
      && !preOffer.hasReceptionAcknowledgment
    ) {
      return true;
    }
    return false;
  });
  const companyRestrictions = (
    documentsToEvaluate[0]?.company?.companyevaluationrestrictionsSet?.filter(
      ({ status }) => [companyRiskRestrictionsStatus.CREATED,
        companyRiskRestrictionsStatus.PENDING].includes(status),
    ) ?? []);

  return (
    <AcceptEvaluationRequestsForm
      company={documentsToEvaluate[0]?.company}
      companyRestrictions={companyRestrictions}
      acceptEvaluationRequests={acceptPreOfferEvaluationRequests}
      analystDescription={analystDescription}
      setAnalystDescription={setAnalystDescription}
      columns={columns}
      disabled={disabled}
      informClients={informClients}
      setInformClients={setInformClients}
      sendEmail={sendEmail}
      setSendEmail={setSendEmail}
      loading={loading}
      error={error}
      rows={preOfferEvaluationRequests}
      resolveRestrictionResolutionRefetchQueries={[GET_COMPANY_EVALUATIONS]}
    />
  );
};

AcceptPreOfferEvaluationRequestsDialog.propTypes = {
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        companyevaluationrestrictionsSet: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            status: PropTypes.string.isRequired,
            restriction: PropTypes.string.isRequired,
            description: PropTypes.string.isRequired,
            resolutionExplanation: PropTypes.string.isRequired,
          }),
        ),
      }),
    }),
  ).isRequired,
  factoringColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
  defaultRatification: PropTypes.string,
  defaultFundingDays: PropTypes.number.isRequired,
};

AcceptPreOfferEvaluationRequestsDialog.defaultProps = {
  defaultRatification: '',
};

export default AcceptPreOfferEvaluationRequestsDialog;
